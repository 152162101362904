import React, { useState, useEffect } from "react";
import { Select, Button, Popconfirm } from "antd";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import ObjectiveForm from "./ObjectiveForm";
import { useDispatch } from "react-redux";
import {
  useGetPerformanceGoals,
  useGetPerformanceGoalObjectives,
} from "../../../../util/usershookActions";
import {
  addPerformanceGoalObjectives,
  updatePerformanceGoalObjectives,
} from "../../../../redux/users/userActions";
import CurrentPerformanceYear from "../currentPerformanceYear";

ChartJS.register(ArcElement, Tooltip, Legend);

const ObjectiveSettings = () => {
  const [enabled, setEnabled] = useState(true);
  const [addObjectiveLoading, setAddObjectiveLoading] = useState(false);
  const [exceedsMax, setExceedsMax] = useState(false);
  const [formList, setFormList] = useState([
    {
      id: 1,
      objective: "",
      weight: "",
      description: "",
    },
  ]);
  const [goal, setGoal] = useState();

  const [totalWeight, setTotalWeight] = useState(0);
  const [availableWeight, setAvailableWeight] = useState();
  const [goalWeight, setGoalWeight] = useState();

  const [buttonStatus, setButtonStatus] = useState(false);

  const dispatch = useDispatch();

  const { data: goalsList } = useGetPerformanceGoals(enabled, setEnabled);

  const { data: objectivesList, refetch: objectivesRefetch } =
    useGetPerformanceGoalObjectives(enabled, setEnabled);

  const data = {
    labels: [
      `Used Weight: ${totalWeight}%`,
      `Available Weight: ${availableWeight}%`,
    ],
    datasets: [
      {
        label: "% of Weight",
        data: [totalWeight, availableWeight],
        backgroundColor: ["#D9D9D9", "#4F4FE5"],
        borderColor: ["#D9D9D9", "#4F4FE5"],
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: false,
      },
    },
  };

  useEffect(() => {
    setAvailableWeight(goalWeight - totalWeight);
  }, [totalWeight, goalWeight]);

  useEffect(() => {
    setFormList([
      {
        id: 1,
        objective: "",
        weight: "",
        description: "",
      },
    ]);
    setTotalWeight(0);
    setAvailableWeight(goalWeight);
  }, [goalWeight]);

  const handleAddForm = () => {
    if (availableWeight > 0) {
      const newForm = {
        id: formList.length + 1,
        objective: "",
        description: "",
        weight: "",
      };
      setTotalWeight(Number(totalWeight + newForm.weight));
      setFormList([...formList, newForm]);
    } else {
      console.log("Available weight is 0");
    }
  };

  const handleWeightChange = (id, newWeight) => {
    if (
      newWeight <=
      availableWeight + formList.find((form) => form.id === id).weight
    ) {
      const updatedFormData = formList.map((form) =>
        form.id === id ? { ...form, weight: newWeight } : form
      );
      setFormList(updatedFormData);

      const newTotalWeight = updatedFormData.reduce(
        (total, form) => total + Number(form.weight),
        0
      );
      setTotalWeight(Number(newTotalWeight));
      setExceedsMax(false);
    } else {
      setExceedsMax(true);
      console.log("Weight exceeds available weight");
    }
  };

  useEffect(() => {
    const requestBody = {
      goal,
      objectives: formList.map((form) => ({
        objective: form.objective,
        weight: form.weight,
        description: form.description,
      })),
    };
    console.log("requestBody", requestBody);
  }, [formList, goal]);

  const handleRemoveForm = (goalNumber) => {
    if (formList.length === 1) return;
    setFormList((prevList) =>
      prevList.filter((form) => form.id !== goalNumber)
    );
  };

  const handleFormChange = (id, name, value) => {
    setFormList((prevFormList) =>
      prevFormList.map((form) =>
        form.id === id ? { ...form, [name]: value } : form
      )
    );
  };

  const handleAddGoalObjectives = (e) => {
    e.preventDefault();
    setAddObjectiveLoading(true);
    const creds = {
      goal_id: goal,
      objectives: formList.map((form) => ({
        objective: form.objective,
        weight: form.weight,
        description: form.description,
      })),
    };
    addPerformanceGoalObjectives(dispatch, creds).then((res) => {
      setAddObjectiveLoading(false);
      if (res.status === "success") {
        objectivesRefetch();
      }
    });
  };

  const handleUpdateGoalObjectives = (e) => {
    e.preventDefault();
    setAddObjectiveLoading(true);
    const creds = {
      goal_id: goal,
      objectives: formList.map((form) => ({
        objective: form.objective,
        weight: form.weight,
        description: form.description,
      })),
    };
    updatePerformanceGoalObjectives(dispatch, creds, goal).then(() => {
      setAddObjectiveLoading(false);
    });
  };

  // Filter `option.label` match the user type `input`
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  console.log("formList", formList);

  // Check if there are any objectives for the selected goal & update the formList state accordingly
  useEffect(() => {
    if (goal && goalsList?.payload?.goals?.length) {
      const filteredObjectives = objectivesList?.payload.filter(
        (o) => o.performance_goal_id === goal
      );

      if (filteredObjectives.length === 0) {
        setFormList([
          {
            id: 1,
            objective: "",
            weight: "",
            description: "",
          },
        ]);
        setTotalWeight(0);
      } else {
        setFormList(
          filteredObjectives.map((o) => ({
            id: o.id,
            objective: o.objective,
            weight: o.weight,
            description: o.description,
          }))
        );
        setTotalWeight(
          filteredObjectives.reduce((total, o) => total + o.weight, 0)
        );
        console.log("filteredObjectives", filteredObjectives);
      }
    } else {
      setFormList([
        {
          id: 1,
          objective: "",
          weight: "",
          description: "",
        },
      ]);
      setTotalWeight(0);
    }
  }, [goal, goalsList, objectivesList]);

  useEffect(() => {
    if (goal) {
      const checkGoal = objectivesList?.payload
        ?.map((o) => o.performance_goal_id)
        .includes(goal);
      setButtonStatus(checkGoal);
    }
  }, [objectivesList, goal]);

  return (
    <div className="container-fluid">
      <div className="app__section py-4 mt-3">
        <div className="card__border__bottom">
          <div className="">
            <h3 className="h4">
              Set Goal Objectives
              <CurrentPerformanceYear />
            </h3>
            <p>
              Define how you aim to achieve the goal set for performance in the
              year
            </p>
          </div>

          <div className="d-flex align-items-center justify-content-between gap-5 flex-wrap ">
            <div className="d-flex flex-column gap-2">
              <label className="fw-bold fs-6">Goal</label>
              <Select
                showSearch
                size="large"
                placeholder="Select a goal"
                optionFilterProp="children"
                onChange={(value, option) => {
                  setGoal(value);
                  setGoalWeight(Number(option.weight));
                }}
                value={goal}
                filterOption={filterOption}
                style={{
                  border: "2px solid black",
                  borderRadius: "10px",
                  width: "100%",
                  minWidth: "20rem",
                }}
                options={goalsList?.payload?.goals?.map((goal) => ({
                  value: goal?.id,
                  label: goal?.goal,
                  weight: goal?.weight,
                }))}
              />
            </div>

            <div
              className="d-flex "
              style={{
                width: "auto",
              }}
            >
              {goal !== ("" || undefined) && (
                <div
                  className="mx-auto"
                  style={{
                    width: "100%",
                    minWidth: "11rem",
                  }}
                >
                  <Doughnut data={data} options={options} />
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="">
          {formList
            .sort((a, b) => a.id - b.id)
            .reverse()
            .map((form) => (
              <ObjectiveForm
                key={form.id}
                objectiveNumber={form.id}
                handleAddForm={handleAddForm}
                handleRemoveForm={handleRemoveForm}
                id={form.id}
                objective={form.objective}
                weight={form.weight}
                description={form.description}
                availableWeight={availableWeight}
                onChange={handleFormChange}
                onWeightChange={handleWeightChange}
                exceedsMax={exceedsMax}
              />
            ))}

          <div className="d-flex justify-content-center">
            {!buttonStatus ? (
              <Button
                type="primary"
                size="large"
                style={{
                  backgroundColor: "var(--clr-primary)",
                }}
                onClick={handleAddGoalObjectives}
                disabled={
                  Object.values(formList).some((value) => value === "") ||
                  goal === "" ||
                  totalWeight < goalWeight
                }
                loading={addObjectiveLoading}
              >
                Submit
              </Button>
            ) : (
              <Popconfirm
                title="Update Objective(s)"
                description="Note that this action will clear all KPIs & targets associated with this Objective(s)."
                onConfirm={handleUpdateGoalObjectives}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  type="primary"
                  size="large"
                  style={{
                    backgroundColor: "var(--clr-primary)",
                  }}
                  disabled={
                    Object.values(formList).some((value) => value === "") ||
                    goal === "" ||
                    totalWeight < goalWeight
                  }
                  loading={addObjectiveLoading}
                >
                  Update
                </Button>
              </Popconfirm>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ObjectiveSettings;
