import React, { useState, useEffect } from "react";
import { Select, Button } from "antd";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import TargetForm from "./TargetForm";
import "../../../../styles/Planning.css";
import { useDispatch } from "react-redux";

import {
  useGetPerformanceGoals,
  useGetPerformanceGoalObjectives,
  useGetPerformanceObjectiveKPIs,
  useGetPerformanceKPITargets,
} from "../../../../util/usershookActions";

import {
  addPerformanceKPITargets,
  updatePerformanceKPITargets,
} from "../../../../redux/users/userActions";
import TargetTable from "./TargetTable";
import SkeletonUI from "../../../ui/Skeleton";
import CurrentPerformanceYear from "../currentPerformanceYear";

ChartJS.register(ArcElement, Tooltip, Legend);

const TargetSettings = () => {
  const [enabled, setEnabled] = useState(true);
  const [addTargetLoading, setAddTargetLoading] = useState(false);
  const [exceedsMax, setExceedsMax] = useState(false);

  const [formList, setFormList] = useState([
    {
      id: 1,
      target: "",
      weight: "",
      unit_id: "",
      start_date: "",
      end_date: "",
      department_id: "",
      description: "",
    },
  ]);
  const [goal_id, setGoalId] = useState();
  const [objective_id, setObjectiveId] = useState();
  const [kpi_id, setKPIId] = useState();

  const [totalWeight, setTotalWeight] = useState(0);
  const [availableWeight, setAvailableWeight] = useState();
  const [kpiWeight, setKPIWeight] = useState();

  const dispatch = useDispatch();

  const { data: goalsList } = useGetPerformanceGoals(enabled, setEnabled);

  const { data: objectivesList } = useGetPerformanceGoalObjectives(
    enabled,
    setEnabled
  );

  const { data: kpisList } = useGetPerformanceObjectiveKPIs(
    enabled,
    setEnabled
  );

  const {
    data: targetsTableData,
    isLoading,
    refetch,
  } = useGetPerformanceKPITargets(enabled, setEnabled);

  const data = {
    labels: [
      `Used Weight: ${totalWeight}%`,
      `Available Weight: ${availableWeight}%`,
    ],
    datasets: [
      {
        label: "% of Weight",
        data: [totalWeight, availableWeight],
        backgroundColor: ["#D9D9D9", "#4F4FE5"],
        borderColor: ["#D9D9D9", "#4F4FE5"],
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: false,
      },
    },
  };

  useEffect(() => {
    setObjectiveId(null);
  }, [goal_id]);

  useEffect(() => {
    setKPIId(null);
    setFormList([
      {
        id: 1,
        target: "",
        weight: "",
        unit_id: "",
        start_date: "",
        end_date: "",
        department_id: "",
        description: "",
      },
    ]);
  }, [objective_id]);

  useEffect(() => {
    setAvailableWeight(kpiWeight - totalWeight);
  }, [totalWeight, kpiWeight]);

  useEffect(() => {
    setFormList((prevList) => prevList.filter((form) => form.id === 1));
    setTotalWeight(0);
    setAvailableWeight(kpiWeight);
  }, [kpiWeight]);

  const handleAddForm = () => {
    if (availableWeight > 0) {
      const newForm = {
        id: formList.length + 1,
        target: "",
        weight: "",
        unit_id: "",
        start_date: "",
        end_date: "",
        department_id: "",
        description: "",
      };
      setTotalWeight(Number(totalWeight + newForm.weight));
      setFormList([...formList, newForm]);
    } else {
      console.log("Available weight is 0");
    }
  };

  const handleWeightChange = (id, newWeight) => {
    if (
      newWeight <=
      availableWeight + formList.find((form) => form.id === id).weight
    ) {
      const updatedFormData = formList.map((form) =>
        form.id === id ? { ...form, weight: newWeight } : form
      );
      setFormList(updatedFormData);

      const newTotalWeight = updatedFormData.reduce(
        (total, form) => total + form.weight,
        0
      );
      setTotalWeight(Number(newTotalWeight));
      setExceedsMax(false);
    } else {
      setExceedsMax(true);
      console.log("Weight exceeds available weight");
    }
  };

  useEffect(() => {
    const requestBody = {
      goal_id,
      objective_id,
      kpi_id,
      targets: formList.map((form) => ({
        id: form.id,
        target: form.target,
        weight: form.weight,
        unit_id: form.unit_id,
        start_date: form.start_date,
        end_date: form.end_date,
        department_id: form.department_id,
        description: form.description,
      })),
    };
    console.log("requestBody", requestBody);
  }, [formList, goal_id, kpi_id, objective_id]);

  const handleRemoveForm = (KPINumber) => {
    if (formList.length === 1) return;
    setFormList((prevList) => prevList.filter((form) => form.id !== KPINumber));
  };

  const handleFormChange = (id, name, value) => {
    setFormList((prevFormList) =>
      prevFormList.map((form) =>
        form.id === id ? { ...form, [name]: value } : form
      )
    );
  };

  const handleAddKPITargets = (e) => {
    e.preventDefault();
    setAddTargetLoading(true);
    const creds = {
      goal_id,
      objective_id,
      kpi_id,
      targets: formList.map((form) => ({
        target: form.target,
        weight: form.weight,
        unit_id: form.unit_id,
        start_date: form.start_date,
        end_date: form.end_date,
        department_id: form.department_id,
        description: form.description,
      })),
    };
    addPerformanceKPITargets(dispatch, creds).then(() => {
      setAddTargetLoading(false);
      refetch();
    });
  };

  const handleUpdateKPITargets = (e) => {
    e.preventDefault();
    setAddTargetLoading(true);
    const creds = {
      goal_id,
      objective_id,
      kpi_id,
      targets: formList.map((form) => ({
        id: form.id,
        target: form.target,
        weight: form.weight,
        unit_id: form.unit_id,
        start_date: form.start_date,
        end_date: form.end_date,
        department_id: form.department_id,
        description: form.description,
      })),
    };
    updatePerformanceKPITargets(dispatch, creds, kpi_id).then(() => {
      setAddTargetLoading(false);
      refetch();
    });
  };

  // Filter `option.label` match the user type `input`
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  useEffect(() => {
    if (kpi_id && targetsTableData.length > 0) {
      const filteredTargets = targetsTableData.filter(
        (target) => target.kpi_id === kpi_id
      );

      if (filteredTargets.length === 0) {
        setFormList([
          {
            id: 1,
            target: "",
            weight: "",
            unit_id: "",
            start_date: "",
            end_date: "",
            department_id: "",
            description: "",
          },
        ]);
        setTotalWeight(0);
      } else {
        setFormList(filteredTargets);
        setTotalWeight(
          filteredTargets.reduce(
            (total, form) => total + Number(form.weight),
            0
          )
        );
      }
    }
  }, [kpi_id, targetsTableData]);

  console.log("formList", formList);
  console.log("targetsTableData", targetsTableData);

  return (
    <div className="container-fluid">
      <div className="app__section py-4 mt-3">
        <div className="card__border__bottom">
          <div className="">
            <h3 className="h4">
              Targets
              <CurrentPerformanceYear />
            </h3>
            <p>Targets can be set to respective KPIs</p>
          </div>

          <div className="d-flex align-items-center justify-content-between gap-5 flex-wrap">
            <div className="d-flex flex-column align-items-between gap-5">
              <div className="d-flex flex-column gap-2">
                <label className="fw-bold fs-6">Goal</label>
                <Select
                  showSearch
                  size="large"
                  placeholder="Select a goal"
                  optionFilterProp="children"
                  value={goal_id}
                  onChange={(value) => {
                    setGoalId(value);
                  }}
                  filterOption={filterOption}
                  style={{
                    border: "2px solid black",
                    borderRadius: "10px",
                    width: "100%",
                    minWidth: "20rem",
                  }}
                  options={goalsList?.payload?.goals?.map((goal) => ({
                    value: goal?.id,
                    label: goal?.goal,
                  }))}
                />
              </div>

              <div className="d-flex flex-column gap-2">
                <label className="fw-bold fs-6">Associated Objective</label>
                <Select
                  showSearch
                  size="large"
                  placeholder="Select Associated Objective"
                  optionFilterProp="children"
                  value={objective_id}
                  onChange={(value) => {
                    setObjectiveId(value);
                  }}
                  filterOption={filterOption}
                  style={{
                    border: "2px solid black",
                    borderRadius: "10px",
                    width: "100%",
                    minWidth: "20rem",
                  }}
                  options={objectivesList?.payload
                    ?.filter(
                      (objective) => objective.performance_goal_id === goal_id
                    )
                    .map((objective) => ({
                      value: objective?.id,
                      label: objective?.objective,
                    }))}
                />
              </div>

              <div className="d-flex flex-column gap-2">
                <label className="fw-bold fs-6">KPI</label>
                <Select
                  showSearch
                  size="large"
                  placeholder="Select KPI"
                  optionFilterProp="children"
                  value={kpi_id}
                  onChange={(value, option) => {
                    setKPIId(value);
                    setKPIWeight(Number(option.weight));
                  }}
                  filterOption={filterOption}
                  style={{
                    border: "2px solid black",
                    borderRadius: "10px",
                    width: "100%",
                    minWidth: "20rem",
                  }}
                  options={kpisList?.payload
                    ?.filter(
                      (kpi) => kpi.performance_objective_id === objective_id
                    )
                    .map((kpi) => ({
                      value: kpi?.id,
                      label: kpi?.name,
                      weight: kpi?.weight,
                    }))}
                />
              </div>
            </div>

            <div
              className="d-flex "
              style={{
                width: "auto",
              }}
            >
              {kpi_id !== null && (
                <div
                  className="mx-auto"
                  style={{
                    width: "100%",
                    minWidth: "11rem",
                  }}
                >
                  <Doughnut data={data} options={options} />
                </div>
              )}
            </div>
          </div>
        </div>

        <div
          className=""
          style={{
            paddingBottom: "30px",
            borderBottom: "1px solid #d9d9d9",
            marginBottom: "30px",
          }}
        >
          {formList
            .sort((a, b) => a.id - b.id)
            .reverse()
            .map((form) => (
              <TargetForm
                key={form.id}
                TargetNumber={form.id}
                handleAddForm={handleAddForm}
                handleRemoveForm={handleRemoveForm}
                id={form.id}
                target={form.target}
                unitID={form.unit_id}
                startDate={form.start_date}
                endDate={form.end_date}
                departmentID={form.department_id}
                weight={form.weight}
                description={form.description}
                availableWeight={availableWeight}
                onChange={handleFormChange}
                onWeightChange={handleWeightChange}
                exceedsMax={exceedsMax}
              />
            ))}

          {formList.length ? (
            <div className="d-flex justify-content-center">
              <Button
                type="primary"
                size="large"
                style={{
                  backgroundColor: "var(--clr-primary)",
                }}
                onClick={
                  targetsTableData
                    ?.map((target) => target.kpi_id)
                    .includes(kpi_id)
                    ? handleUpdateKPITargets
                    : handleAddKPITargets
                }
                disabled={
                  kpi_id === null ||
                  goal_id === null ||
                  objective_id === null ||
                  totalWeight < kpiWeight
                }
                loading={addTargetLoading}
              >
                {targetsTableData
                  ?.map((target) => target.kpi_id)
                  .includes(kpi_id)
                  ? "Update"
                  : "Submit"}
              </Button>
            </div>
          ) : null}
        </div>

        <div className="target__table ">
          {isLoading ? (
            <SkeletonUI />
          ) : (
            <TargetTable tableData={targetsTableData} />
          )}
        </div>
      </div>
    </div>
  );
};

export default TargetSettings;
