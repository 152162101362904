import React, { useState, useEffect, useMemo } from "react";
import { Select, Button, Popconfirm } from "antd";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import KPIForm from "./KPIForm";
import { useDispatch } from "react-redux";

import {
  useGetPerformanceGoals,
  useGetPerformanceGoalObjectives,
  useGetPerformanceObjectiveKPIs,
} from "../../../../util/usershookActions";

import {
  addPerformanceObjectiveKPIs,
  updatePerformanceObjectiveKPIs,
} from "../../../../redux/users/userActions";
import CurrentPerformanceYear from "../currentPerformanceYear";

ChartJS.register(ArcElement, Tooltip, Legend);

const KPISettings = () => {
  const [enabled, setEnabled] = useState(true);
  const [addKPILoading, setAddKPILoading] = useState(false);
  const [exceedsMax, setExceedsMax] = useState(false);

  const [formList, setFormList] = useState([
    {
      id: 1,
      name: "",
      weight: "",
      description: "",
    },
  ]);
  const [goal_id, setGoalId] = useState();
  const [objective_id, setObjectiveId] = useState();

  const [totalWeight, setTotalWeight] = useState(0);
  const [availableWeight, setAvailableWeight] = useState();
  const [objectiveWeight, setObjectiveWeight] = useState();

  const [buttonStatus, setButtonStatus] = useState(false);

  const dispatch = useDispatch();

  const { data: goalsList } = useGetPerformanceGoals(enabled, setEnabled);

  const { data: objectivesList } = useGetPerformanceGoalObjectives(
    enabled,
    setEnabled
  );

  const { data: kpisList, refetch: kpisRefetch } =
    useGetPerformanceObjectiveKPIs(enabled, setEnabled);
  const data = {
    labels: [
      `Used Weight: ${totalWeight}%`,
      `Available Weight: ${availableWeight}%`,
    ],
    datasets: [
      {
        label: "% of Weight",
        data: [totalWeight, availableWeight],
        backgroundColor: ["#D9D9D9", "#4F4FE5"],
        borderColor: ["#D9D9D9", "#4F4FE5"],
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: false,
      },
    },
  };

  useEffect(() => {
    setObjectiveId(null);
  }, [goal_id]);

  useEffect(() => {
    setAvailableWeight(objectiveWeight - totalWeight);
  }, [totalWeight, objectiveWeight]);

  useEffect(() => {

    setFormList([
      {
        id: 1,
        name: "",
        weight: "",
        description: "",
      },
    ]);
    setTotalWeight(0);
    setAvailableWeight(objectiveWeight);
  }, [objectiveWeight]);

  const handleAddForm = () => {
    if (availableWeight > 0) {
      const newForm = {
        id: formList.length + 1,
        name: "",
        description: "",
        weight: "",
      };
      setTotalWeight(Number(totalWeight + newForm.weight));
      setFormList([...formList, newForm]);
    } else {
      console.log("Available weight is 0");
    }
  };

  const handleWeightChange = (id, newWeight) => {
    if (
      newWeight <=
      availableWeight + formList.find((form) => form.id === id).weight
    ) {
      const updatedFormData = [...formList].map((form) =>
        form.id === id ? { ...form, weight: newWeight } : form
      );
      setFormList(updatedFormData);

      const newTotalWeight = updatedFormData.reduce(
        (total, form) => total + form.weight,
        0
      );
      setTotalWeight(Number(newTotalWeight));
      setExceedsMax(false);
    } else {
      setExceedsMax(true);
      console.log("Weight exceeds available weight");
    }
  };

  useEffect(() => {
    const requestBody = {
      goal_id,
      objective_id,
      kpis: formList.map((form) => ({
        name: form.name,
        weight: form.weight,
        description: form.description,
      })),
    };
    console.log("requestBody", requestBody);
  }, [formList, goal_id, objective_id]);

  console.log("formList", formList);

  const handleRemoveForm = (KPINumber) => {
    if (formList.length === 1) return;

    setFormList((prevList) => prevList.filter((form) => form.id !== KPINumber));
  };

  const handleFormChange = (id, name, value) => {
    setFormList((prevFormList) => 
      prevFormList.map((form) =>
        form.id === id ? { ...form, [name]: value } : form
      )
    );
  };

  const handleAddObjectiveKPIs = (e) => {
    e.preventDefault();
    setAddKPILoading(true);
    const creds = {
      goal_id,
      objective_id,
      kpis: formList.map((form) => ({
        name: form.name,
        weight: form.weight,
        description: form.description,
      })),
    };
    addPerformanceObjectiveKPIs(dispatch, creds).then(() => {
      setAddKPILoading(false);
      kpisRefetch();
    });
  };

  const handleUpdateObjectiveKPIs = (e) => {
    e.preventDefault();
    setAddKPILoading(true);
    const creds = {
      goal_id,
      objective_id,
      kpis: formList.map((form) => ({
        name: form.name,
        weight: form.weight,
        description: form.description,
      })),
    };
    updatePerformanceObjectiveKPIs(dispatch, creds, objective_id).then(() => {
      setAddKPILoading(false);
    });
  };

  // Filter `option.label` match the user type `input`
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  // Use effect to update formList based on filtered KPIs
  useEffect(() => {
    if (goal_id && objective_id && kpisList?.payload) {
      const filteredKPIs = kpisList.payload.filter(
        (kpi) => kpi.performance_objective_id === objective_id
      );

      if (filteredKPIs.length === 0) {
        setFormList([
          {
            id: 1,
            name: "",
            weight: "",
            description: "",
          },
        ]);
        setTotalWeight(0);
      } else {
        const newFormList = filteredKPIs.map((kpi) => ({
          id: kpi.id,
          name: kpi.name,
          weight: kpi.weight,
          description: kpi.description,
        }));
        setFormList(newFormList);
        setTotalWeight(
          filteredKPIs.reduce((total, kpi) => total + Number(kpi.weight), 0)
        );
      }
    }
  }, [goal_id, kpisList, objective_id]);

  useEffect(() => {
    if (objective_id) {
      const checkObjectiveId = kpisList?.payload
        ?.map((kpi) => kpi.performance_objective_id)
        .includes(objective_id);
      setButtonStatus(checkObjectiveId);
    }
  }, [kpisList, objective_id]);

  return (
    <div className="container-fluid">
      <div className="app__section py-4 mt-3">
        <div className="card__border__bottom">
          <div className="">
            <h3 className="h4">
              Set Key Performance Indicator
              <CurrentPerformanceYear />
            </h3>
            <p>
              Define how you aim to achieve the goal set for performance in the
              year
            </p>
          </div>

          <div className="d-flex align-items-center justify-content-between ">
            <div className="d-flex flex-column align-items-between gap-5">
              <div className="d-flex flex-column gap-2">
                <label className="fw-bold fs-6">Goal</label>
                <Select
                  showSearch
                  size="large"
                  placeholder="Select a goal"
                  optionFilterProp="children"
                  value={goal_id}
                  onChange={(value) => {
                    setGoalId(value);
                  }}
                  filterOption={filterOption}
                  style={{
                    border: "2px solid black",
                    borderRadius: "10px",
                    width: "100%",
                    minWidth: "20rem",
                  }}
                  options={goalsList?.payload?.goals.map((goal) => ({
                    value: goal?.id,
                    label: goal?.goal,
                  }))}
                />
              </div>

              <div className="d-flex flex-column gap-2">
                <label className="fw-bold fs-6">Associated Objective</label>
                <Select
                  showSearch
                  size="large"
                  placeholder="Select Associated Objective"
                  optionFilterProp="children"
                  filterOption={filterOption}
                  value={objective_id}
                  onChange={(value, option) => {
                    setObjectiveId(value);
                    setObjectiveWeight(Number(option.weight));
                  }}
                  style={{
                    border: "2px solid black",
                    borderRadius: "10px",
                    width: "100%",
                    minWidth: "20rem",
                  }}
                  options={objectivesList?.payload
                    ?.filter(
                      (objective) => objective.performance_goal_id === goal_id
                    )
                    .map((objective) => ({
                      value: objective?.id,
                      label: objective?.objective,
                      weight: objective?.weight,
                    }))}
                />
              </div>
            </div>

            <div
              className="d-flex "
              style={{
                width: "auto",
              }}
            >
              {objective_id !== null && (
                <div
                  className="mx-auto"
                  style={{
                    width: "100%",
                    minWidth: "11rem",
                  }}
                >
                  <Doughnut data={data} options={options} />
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="">
          {formList
            .sort((a, b) => a.id - b.id)
            .reverse()
            .map((form) => (
              <KPIForm
                key={form.id}
                KPINumber={form.id}
                handleAddForm={handleAddForm}
                handleRemoveForm={handleRemoveForm}
                id={form.id}
                KPIName={form.name}
                weight={form.weight}
                description={form.description}
                availableWeight={availableWeight}
                onChange={handleFormChange}
                onWeightChange={handleWeightChange}
                exceedsMax={exceedsMax}
              />
            ))}

          <div className="d-flex justify-content-center">
            {!buttonStatus ? (
              <Button
                type="primary"
                size="large"
                style={{
                  backgroundColor: "var(--clr-primary)",
                }}
                onClick={handleAddObjectiveKPIs}
                disabled={
                  goal_id === null ||
                  objective_id === null ||
                  totalWeight < objectiveWeight
                }
                loading={addKPILoading}
              >
                Submit
              </Button>
            ) : (
              <Popconfirm
                title="Update KPI(s)"
                description="Note that this action will clear all targets associated with this KPI(s)."
                onConfirm={handleUpdateObjectiveKPIs}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  type="primary"
                  size="large"
                  style={{
                    backgroundColor: "var(--clr-primary)",
                  }}
                  disabled={
                    goal_id === null ||
                    objective_id === null ||
                    totalWeight < objectiveWeight
                  }
                  loading={addKPILoading}
                >
                  Update
                </Button>
              </Popconfirm>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default KPISettings;
