import {
  Button,
  DatePicker,
  Space,
  Drawer,
  Card,
  Popconfirm,
  Table,
} from "antd";
import React, { useState, useEffect } from "react";
import { useShallowEqualSelector, useUserCleanUp } from "../../../util/hooks";
import { user_errors } from "../../../util/selectors/userSelectors";

import { useDispatch } from "react-redux";
import {
  useGetEmployeeBatchPaymentStatus,
  useGetUserBatchPaymentTemplate,
} from "../../../util/usershookActions";

import { sendEmployeePayslip, exportPayslip } from "../../../redux/users/userActions";

import dayjs from "dayjs";
import NoContent from "../../ui/NoContent";
import SkeletonUI from "../../ui/Skeleton";
import { formatMoney } from "../../../util/helpers";

function EmployeeSendPayslip() {
  useUserCleanUp();
  const [start_date, setStartDate] = useState("");
  const [end_date, setEndDate] = useState("");
  const [batchStatusEnabled, setBatchStatusEnabled] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [selected_payments, setSelectedPayments] = useState([]);
  const [sendpayslip_loading, setSendPaySlipLoading] = useState(false);
  const [exportpayslip_loading, setExportPaySlipLoading] = useState(false);


  const dispatch = useDispatch();
  const back_errors = useShallowEqualSelector(user_errors);

  const {
    data: batch_data,
    isLoading: batchLoading,
    refetch,
  } = useGetEmployeeBatchPaymentStatus(
    batchStatusEnabled,
    setBatchStatusEnabled,
    start_date,
    end_date,
  );

  useEffect(() => {
    if (batch_data) {
      const new_array = batch_data?.payload?.map((template, index) => {
        return {
          key: template.id,
          user_name: template.user_name,
          company: template.company,
          branch: template.branch,
          ...template.payroll_items.reduce((acc, item) => {
            acc[item.payroll_item_name] = item.amount;
            return acc;
          }, {}),
          paye: template.paye,
          pension_employee: template.pension_employee,
          pension_employer: template.pension_employer,
          template,
        };
      });
      setTableData(new_array);
    }
  }, [batch_data]);

  const columns = [
    {
      title: "Employee name",
      dataIndex: "user_name",
      key: "user_name",
    },
    {
      title: "Company",
      dataIndex: "company",
      key: "company",
      render: (value) => (value === null ? "N/A" : value),
    },
    {
      title: "Branch",
      dataIndex: "branch",
      key: "branch",
      render: (value) => (value === null ? "N/A" : value),
    },
    {
      title: "Payroll Items",
      children: batch_data?.payload?.[0]?.payroll_items.map((item) => ({
        title: item.payroll_item_name,
        dataIndex: item.payroll_item_name,
        align: "center",
        render: (value) => (value === null ? "N/A" : formatMoney(value)),
      })),
    },
    {
      title: "Paye",
      dataIndex: "paye",
      key: "paye",
      render: (value) => (value === null ? "N/A" : formatMoney(value)),
    },
    {
      title: "Pension Employee",
      dataIndex: "pension_employee",
      key: "pension_employee",
      render: (value) => (value === null ? "N/A" : formatMoney(value)),
    },
    {
      title: "Pension Employer",
      dataIndex: "pension_employer",
      key: "pension_employer",
      render: (value) => (value === null ? "N/A" : formatMoney(value)),
    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      render: (_, record) => <EachRecord record={record} refetch={refetch} />,
    },
  ];


  const handleSendPaymentSlips = () => {
    const creds = {
      ids: selected_payments,
    };
    setSendPaySlipLoading(true);
    sendEmployeePayslip(dispatch, creds).then((res) => {
      setSendPaySlipLoading(false);
      refetch();
    });
  };

  const handleExportPaymentSlips = () => {
    const creds = {
      ids: selected_payments,
    };
    setExportPaySlipLoading(true);
    exportPayslip(dispatch, creds).then((res) => {
      setExportPaySlipLoading(false);
      refetch();
    });
  };


  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedPayments(newSelectedRowKeys);
  };
  const rowSelection = {
    selected_payments,
    onChange: onSelectChange,
  };
  const hasSelected = selected_payments.length > 0;

  console.log("selected_payments", selected_payments);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12 min-vh-100">
          <section className="app__section py-4">
            <div
              className="d-flex align-items-center justify-content-between"
              style={{
                padding: "1rem",
                borderBottom: "1px solid #dee2e6",
              }}
            >
              <div>
                <h5 className="mb-0">Send Your Payslip</h5>
                <small className=" d-none d-sm-block">
                  Send/Download monthly payslip to yourself, detailing your
                  earnings, deductions, and net pay for the current pay period.
                </small>
              </div>
            </div>

            <div className="w-75 bg-light mx-auto p-3 my-5 shadow-sm">
              <form className="">
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <div className="d-flex justify-content-between flex-wrap mb-2">
                      <label htmlFor="">Pay Month</label>

                      <small className="text-danger">
                        {back_errors.start_date && back_errors.start_date[0]}
                      </small>
                    </div>

                    <div className="d-flex gap-3">
                      <DatePicker
                        onChange={(date, dateString) =>
                          setStartDate(date.format("YYYY-MM"))
                        }
                        value={start_date ? dayjs(start_date, "YYYY-MM") : null}
                        placeholder="Start date"
                        className="w-100 batch-month"
                        size="large"
                        picker="month"
                      />
                      <DatePicker
                        onChange={(date, dateString) =>
                          setEndDate(date.format("YYYY-MM"))
                        }
                        value={end_date ? dayjs(end_date, "YYYY-MM") : null}
                        placeholder="End date"
                        className="w-100 batch-month"
                        size="large"
                        picker="month"
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="d-flex justify-content-end  ">
                    <Space>
                      <Button
                        type="primary"
                        className="myBtn d-flex align-items-center"
                        onClick={() => setBatchStatusEnabled(true)}
                        loading={batchLoading}
                        disabled={!start_date || !end_date}
                      >
                        Submit
                      </Button>
                    </Space>
                  </div>
                </div>
              </form>
            </div>

            <div className="row">
              <div
                style={{
                  padding: "1rem",
                }}
                className="row"
              >
                {batchLoading ? (
                  <SkeletonUI number={2} />
                ) : batch_data?.payload?.length ? (
                  <>
                    <Table
                      rowSelection={rowSelection}
                      columns={columns}
                      dataSource={tableData}
                      scroll={{ x: "max-content" }}
                    />

                    <div className="row">
                      <div className="d-flex justify-content-center gap-3  ">
                        <Button
                          className="myBtn d-flex align-items-center"
                          disabled={!hasSelected}
                          loading={sendpayslip_loading}
                          onClick={handleSendPaymentSlips}
                        >
                          Send Payslip(s)
                        </Button>
                        <Button
                          className="myBtn d-flex align-items-center bg-success"
                          disabled={!hasSelected}
                          loading={exportpayslip_loading}
                          onClick={handleExportPaymentSlips}
                        >
                          Export Payslip(s)
                        </Button>
                      </div>
                    </div>
                  </>
                ) : (
                  <NoContent
                    width={"400px"}
                    message={"Load Authorized Payments"}
                    illustration={"/assets/selected.png"}
                  />
                )}
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

function EachRecord({ record, refetch }) {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (record.template.user_id && open) {
      setEnabled(true);
    }
  }, [open, record.template.user_id]);

  const {
    data: user_batch_payment_template,
    isLoading: user_batch_payment_template_loading,
  } = useGetUserBatchPaymentTemplate(
    enabled,
    setEnabled,
    record.template.user_id
  );

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const formatSubKey = (subKey) => {
    if (subKey.includes("_")) {
      return subKey
        .split("_")
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join(" ");
    } else {
      // If the string is not camel cased, just capitalize it
      return subKey.charAt(0).toUpperCase() + subKey.slice(1);
    }
  };

  const confirm = () => {
    const creds = {
      ids: [record?.template?.id],
    };
    setLoading(true);
    sendEmployeePayslip(dispatch, creds).then((res) => {
      setLoading(false);
      refetch();
    });
  };

  return (
    <Space wrap>
      <Popconfirm
        title="Send payment slip"
        description="Are you sure want to perform this action?"
        onConfirm={confirm}
        okText="Yes"
        cancelText="No"
      >
        <Button
          className="rounded p-1 px-2 border border-1 text-white me-1 bg-primary"
          loading={loading}
        >
          Send Payslip
        </Button>
      </Popconfirm>
      <Space>
        <Button
          onClick={showDrawer}
          className="rounded p-1 px-2 border border-1 text-white me-1 bg-success"
        >
          View
        </Button>
      </Space>

      <Drawer
        title={record.user_name}
        onClose={onClose}
        open={open}
        extra={
          <Space>
            <Button danger size="large" onClick={onClose}>
              Close
            </Button>
          </Space>
        }
        destroyOnClose={true}
        width={"90%"}
      >
        <div>
          <div className="row">
            <h5>Payment Template for {record.user_name}</h5>

            <div className="d-flex gap-2  flex-wrap row  mx-auto justify-content-between shadow-lg mt-3 p-3 bg-light">
              {user_batch_payment_template_loading && <SkeletonUI number={2} />}
              {user_batch_payment_template?.payload
                ? Object.keys(user_batch_payment_template?.payload).map((key) =>
                    key === "user_name" ? null : (
                      <Card
                        title={key}
                        key={key}
                        className="mb-2"
                        bordered={false}
                        style={{
                          width: "49%",
                        }}
                      >
                        {Array.isArray(
                          user_batch_payment_template?.payload[key]
                        ) &&
                        user_batch_payment_template?.payload[key].length > 0 ? (
                          user_batch_payment_template?.payload[key].map(
                            (item) => (
                              <div className="d-flex justify-content-between flex-nowrap">
                                <p>{item.payroll_item_name}:</p>
                                <p>{formatMoney(item.amount)} (NGN)</p>
                              </div>
                            )
                          )
                        ) : Object.keys(
                            user_batch_payment_template?.payload[key]
                          ).length > 0 ? (
                          Object.keys(
                            user_batch_payment_template?.payload[key]
                          ).map((subKey) => (
                            <div className="d-flex justify-content-between flex-nowrap">
                              <p>{formatSubKey(subKey)}:</p>
                              <p>
                                {formatMoney(
                                  user_batch_payment_template?.payload[key][
                                    subKey
                                  ]
                                )}{" "}
                                (NGN)
                              </p>
                            </div>
                          ))
                        ) : (
                          <p>No item available</p>
                        )}
                      </Card>
                    )
                  )
                : null}
            </div>
          </div>
        </div>
      </Drawer>
    </Space>
  );
}

export default EmployeeSendPayslip;
